<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/user.png" alt />
        <span class="center">用户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">二类户列表</span>
      </div>
      <div style="display: flex; align-items: center">
        <div style="display: flex; align-items: center">
          <span class="seach_text">关键字：</span>
          <el-input
            placeholder="请输入人员姓名"
            v-model="keywords"
            @input="query"
            size="mini"
            style="margin-right: 10px; width: 300px"
            prefix-icon="el-icon-search"
            clearable
          ></el-input>
        </div>
        <div style="display: flex; align-items: center">
          <span class="seach_text">日期：</span>
          <el-date-picker
            v-model="value"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            placeholder="选择日期时间"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            size="mini"
            @change="getrq(value)"
          ></el-date-picker>
        </div>
        <el-button style="margin-left:20px" type="primary" size="small" @click="exportAll">导出</el-button>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="姓名">
        <template slot-scope="scope">
          <span>{{ scope.row.accountName }}</span>
        </template>
      </el-table-column>
      <el-table-column label=" 手机号（二类户开户）">
        <template slot-scope="scope">
          <span>{{ scope.row.bindPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column label=" 身份证">
        <template slot-scope="scope">
          <span v-if="scope.row.user">{{ scope.row.user.idCardNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="二类户卡号">
        <template slot-scope="scope">
          <span>{{ scope.row.accountCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="开户时间">
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { api } from '../../api/base';
import { parseJsonToTable } from '/src/lib/dev';
export const bankCardListAll = api()(
  'bank.card.list.all.json'
);
export default {
  data () {
    return {
      keywords: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      value: '',
      lt_createTime: '',
      gt_createTime: '',
      tableData: []
    }
  },
  methods: {
    async exportAll () {
      const params = {
        pageSize: 1000000,
        pageNumber: 1,
        keywords: this.keywords,
        cardType: '2',
        lt_createTime: this.lt_createTime,
        gt_createTime: this.gt_createTime
      };
      const res = await bankCardListAll(params)
      const _list = res.list.map((it) => {
        return {
          ...it,
        };
      });

      parseJsonToTable(
        '二类户列表',
        {
          姓名: 'accountName',
          手机号_二类户开户: 'bindPhone',
          身份证号: 'user.idCardNumber',
          银行: 'bankName',
          二类户卡号: 'accountCode',
          开卡时间: 'createTime',
        },
        _list,
        true,
      );

    },
    query () {
      this.pageNumber = 1;
      this.loadData();
    },
    getrq (date) {

      this.gt_createTime = date[0].getFullYear() + '-' + (date[0].getMonth() + 1) + '-' + (date[0].getDate()) + ' 00:00:00';
      this.lt_createTime = date[1].getFullYear() + '-' + (date[1].getMonth() + 1) + '-' + (date[1].getDate()) + ' 23:59:59';
      this.loadData()
    },
    getLocalTime (nS) {

      return this.gt_createTime = new Date(parseInt(nS) * 1000).toLocaleDateString().replace(/:\d{1,2}$/, ' ');
    },
    format (nS) {
      return this.lt_createTime = new Date(parseInt(nS) * 1000).toLocaleDateString().replace(/:\d{1,2}$/, ' ');
    },
    handleSizeChange (val) {
      this.loading = true;
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.loading = true;
      this.pageNumber = val;
      this.loadData();
    },
    handleClose () {
      this.dialogVisible = false;
      this.bmdList = this.$store.state.badMaker.nameList;
    },
    async loadData () {
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        keywords: this.keywords,
        cardType: '2',
        lt_createTime: this.lt_createTime,
        gt_createTime: this.gt_createTime
      };
      const res = await bankCardListAll(params)
      this.tableData = res.list
      this.total = res.total;
    },
  },
  mounted () {
    var now = new Date(); //当前日期 
    var nowMonth = now.getMonth(); //当前月 
    var nowYear = now.getFullYear(); //当前年 
    //本月的开始时间
    var monthStartDate = new Date(nowYear, nowMonth, 1);
    //本月的结束时间
    var monthEndDate = new Date(nowYear, nowMonth + 1, 0);
    this.gt_createTime = monthStartDate.getFullYear() + '-' + (monthStartDate.getMonth() + 1) + '-' + (monthStartDate.getDate()) + ' 00:00:00';
    this.lt_createTime = monthEndDate.getFullYear() + '-' + (monthEndDate.getMonth() + 1) + '-' + (monthEndDate.getDate()) + ' 23:59:59';
    this.value = [this.gt_createTime, this.lt_createTime]
    this.loadData()
  },
}
</script>
<style lang="scss">
</style>